<template>
    <div id="recruitment">
        <h2 class="title">人才招聘</h2>
        <div class="content">
            <h2>{{ isEn ? detail.enTitle : detail.cnTitle }}</h2>
            <div class="text" v-html="isEn ? detail.enContent : detail.cnContent"></div>
        </div>
    </div>
</template>

<script>
    import {urls} from '@/lib/common'
    export default {
        name: "Recruitment",
        data() {
            return {
                isEn: false,
                detail: '',
                navId: '',
                parentId: '',

            }
        },
        watch: {
            "$i18n.locale": function(val) {
                this.isEn = val === 'en' ? true : false;
            }
        },
        methods: {
            getInfo() {
                this.$http.get(urls.getArticleDetail + this.navId).then(res => {
                    if (res.data.status === 200) {
                        this.detail = res.data.data;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            }
        },
        mounted() {
            this.navId = window.atob(decodeURIComponent(this.$route.query.n))
            this.parentId = window.atob(decodeURIComponent(this.$route.query.p))
            this.getInfo();
        }
    }
</script>

<style lang="less" scoped>
    #recruitment {
        width: 70vw;
        margin: auto;
        .title {
            font-size: 1.875vw;
            margin-bottom: 3vw;
        }
        .content {
            line-height: 200%;
            margin-bottom: 5%;
            >h2 {
                font-size: 1.875vw;
                margin-bottom: 2vw;
            }
            .intr {
                text-indent: 2em;
            }
            .text {
                font-size: 0.9375vw;
                color: #353968;
                margin-top: 2vw;
                .red {
                    color: #D93C45;
                }
                a {
                    color: #353968;
                }
            }
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #recruitment{
            width: 90%;
            .title{
                .fontFn(20);
            }
            .content{
                &>h2{
                    .fontFn(16);
                }
                .text{
                    font-size: 16px;
                    span{
                        word-break: break-all;
                    }
                }
            }
        } 
    }
</style>